export const divStyle = {
  display: "block",
  width: 100,
  height: 100,
  background: "#27253f",
  borderRadius: "10px"
};

export const buttonStyle = {
  display: "block",
  height: 30,
  width: 100,
  fontSize: 15,
  marginTop: 20
};
